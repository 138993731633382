import React from 'react'
import {CheckIcon} from '@heroicons/react/outline'

import featureImage from "../../../static/images/hero.png"
// In order to get the right size image, create a dev-tools responsive mobile page set to 1216x880. Use "Capture Screenshot"
// in the top right menu to capture the sized image. Otherwise the dimensions will be funky.
// Wrap in browser frame
import modulesImage from "../../../static/images/clove-modules.png"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LetsTalk from '../../components/LetsTalk'

const FEATURE_COLUMN_SIZE = 5

const featureList = [
  'Manage knowledge base content',
  'Sync content from your existing tools',
  'Personalized content delivery',
  'Comprehensive access controls on all content',
  'Track which users engage with which content',
  // Column
  'Customers manage their support requests',
  'Federated search all of your content',
  '100% customizable design',
  'SSO with your product',
  'Integrate content back into your product'
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CustomerHub(props) {
  return (
    <Layout>
      <Seo title="Customer Hub Use Case | Clove" />

      <div className="pt-10 bg-white sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <div className="lg:pt-12">
                <h1 className="heading-hero">
                  <span className="block">Evolve your help center into a</span>
                  <span className="block bg-clip-text text-transparent bg-gradient-to-r from-brand-400 to-brand-700 sm:pb-5">
                    customer hub.
                  </span>
                </h1>

                <div className="max-w-prose space-y-2 text-primary leading-relaxed">
                  <p>
                    Your customer hub is the foundation of your post-sales customer experience. Users need help as they
                    learn to use your product, and a self-service customer hub gives them what they need to be successful.
                  </p>
                  <p>

                    Clove takes this to the next level by putting the right content in front of the right users, at the right time.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                <img
                  className="max-w-full lg:pt-10 lg:w-9/12 lg:mx-auto"
                  src={featureImage}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="xl:grid xl:grid-cols-3 xl:gap-x-8">
            <div>
              <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">Success starts here</h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900">Customer Hub Features</p>
              <p className="mt-4 text-lg text-gray-500">
                Build a world-class success experience that's focused on what matters most: your customers.
              </p>
            </div>

            <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
              <ul className="divide-y divide-gray-200">
                {featureList.slice(0, FEATURE_COLUMN_SIZE).map((feature, featureIdx) => (
                  <li key={feature} className={classNames(featureIdx === 0 ? 'md:py-0 md:pb-4' : '', 'py-4 flex')}>
                    <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-sm text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>

              <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
                {featureList.slice(FEATURE_COLUMN_SIZE).map((feature, featureIdx) => (
                  <li
                    key={feature}
                    className={classNames(featureIdx === 0 ? 'md:border-t-0 md:py-0 md:pb-4' : '', 'py-4 flex')}
                  >
                    <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-sm text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-brand-600 uppercase">
              Your Tools &mdash; Your Process
            </h2>
            <p className="heading-primary">
              Keep using the success software you use today.
            </p>
            <p className="mt-5 max-w-prose mx-auto text-primary">
              Your success program is built around agent-optimized tools. With Clove, you keep using
              those tools to drive a great experience for your customers.
            </p>
          </div>
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
            <img
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src={modulesImage}
              alt="Clove connects to your existing tools"
            />
          </div>
        </div>
      </div>

      <LetsTalk />
    </Layout>
  )
}
